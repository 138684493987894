import { UtilitiesService } from './../../services/utilities.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchFields } from 'src/app/components/autocomplete-search/autocomplete-search.component';
import { JobQuery } from 'src/app/services/compi-loader-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  jobPlaceholder: string = 'Busca usted un jardinero, una empleada del hogar o incluso un DJ?'

  constructor(private utilities:UtilitiesService) { }

  ngOnInit(): void {
  }

  onSearch(searchMap: Map<SearchFields,string>){
    let query:JobQuery={
      jobName: searchMap.get(SearchFields.JOBFIELD),
      addressQuery: searchMap.get(SearchFields.ADDRESSFIELD)
     }
     this.utilities.setJobQuery(query)
  }

}
