<mat-card>
  <mat-card-header>
    <mat-card-title>¡Haga su cita!</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-divider></mat-divider>
    <div class="pt-4">
      <mat-radio-group
      [(ngModel)]="singleDate"
       aria-label="Seleccione su tipo de cita">
        <mat-radio-button [value]="true">Una sola cita</mat-radio-button>
        <mat-radio-button [value]="false">Repetir cada Semana</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row">
      <div class="col">
        <div *ngIf="singleDate" (click)="$event.stopPropagation()">
          <mat-calendar #calendar
          (selectedChange)="select($event,calendar)"
           [dateClass]="isSelected"
           [dateFilter]="myFilter">
          </mat-calendar>
        </div>
        <div *ngIf="!singleDate">
          <button matRipple *ngFor="let day of daysOfTheWeek" class="week-badge" (click)="day.active = !day.active" [ngClass]="{'active': day.active}"> {{day.day}}</button>
        </div>
      </div>
    </div>
    <mat-menu #appMenu="matMenu">

    </mat-menu>
  </mat-card-content>
</mat-card>
