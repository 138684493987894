import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum PunctualityRateValues{
  NONE = "No hay calificaciones",
  NOTPUNCTUAL = "No es muy puntual",
  OK = "Regular",
  PUNCTUAL = "Muy Puntual"
}

export interface PunctualityRate{
  punctualityIndex:number;
  highlighted: boolean;
  rateLabel:PunctualityRateValues;
}

@Component({
  selector: 'app-punctuality-rating',
  templateUrl: './punctuality-rating.component.html',
  styleUrls: ['./punctuality-rating.component.scss']
})
export class PunctualityRatingComponent implements OnInit {
  @Input() mini:boolean = false;
  @Input() startingValue:number;
  @Input() punctualityScore:number;

  @Output() newPunctualityEvent:EventEmitter<number> = new EventEmitter<number>();

  punctuality:PunctualityRate = {punctualityIndex:0, highlighted: false, rateLabel:PunctualityRateValues.NONE}

  clocks: PunctualityRate[] = [
    {punctualityIndex:1, highlighted: false, rateLabel:PunctualityRateValues.NOTPUNCTUAL},
    {punctualityIndex:2, highlighted: false, rateLabel:PunctualityRateValues.OK},
    {punctualityIndex:3, highlighted: false, rateLabel:PunctualityRateValues.PUNCTUAL},
  ]

  constructor() { }

  ngOnInit(): void {
    if(this.punctualityScore){
      this.punctuality = this.getClockRateByNumber(this.punctualityScore)
      this.highlight(this.punctuality)
    }
  }

  getClockRateByNumber(value:number):PunctualityRate{
    value = Math.round(value);
    let clock = this.clocks.filter(clock => clock.punctualityIndex == value)[0]
    return clock
  }


  rate(value: PunctualityRate){
    if(value==this.punctuality){
      this.punctuality = {punctualityIndex:0, highlighted: false, rateLabel:PunctualityRateValues.NONE}
    }else{
      this.punctuality = value
    }
    this.highlight(this.punctuality)
    this.newPunctualityEvent.emit(this.punctuality.punctualityIndex)
  }

  // This only highlights the value
  highlight(value: PunctualityRate) {
    this.clocks.map(m => value.punctualityIndex >= m.punctualityIndex? m.highlighted=true:m.highlighted=false)
  }

}
