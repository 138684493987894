<div class="row my-4 py-md-4">
  <div class="col d-flex justify-content-center">
    <button (click)="paginate(1)" mat-icon-button color="primary" *ngIf="page>2" aria-label="Example icon button with a menu icon"><mat-icon>first_page</mat-icon></button>
    <button (click)="paginate(+page-1)" mat-icon-button color="primary" *ngIf="page>1"  aria-label="Example icon button with a menu icon"><mat-icon>chevron_left</mat-icon></button>

    <button (click)="paginate(+page -1)" *ngIf="page>1" mat-icon-button>  {{+page -1}}</button>
    <button                              mat-mini-fab color="primary">    {{+page}}</button>
    <button (click)="paginate(+page + 1)" mat-icon-button>                {{+page + 1}}</button>

    <button (click)="paginate(+page + 1)" mat-icon-button color="primary" aria-label="Example icon button with a menu icon"><mat-icon>chevron_right</mat-icon></button>
    <!-- <button mat-icon-button color="primary" aria-label="Example icon button with a menu icon"><mat-icon>last_page</mat-icon></button> -->
  </div>
</div>
