<main class="pt-3">
  <div class="container">

    <div *ngIf="jobsList">

      <!-- JobList -->
      <div *ngIf="jobsList?.length> 0">
        <div class="row d-inline">
          <h1 class="display-3 my-md-3">Estos Compis trabajan de "{{query.jobName}}" cerca de ti</h1>
        </div>
        <div class="row">
          <div class="col-12 mb-3 px-2 d-flex justify-content-center"
          *ngFor="let job of jobsList">
          <compi-card [job]="job" ></compi-card>
        </div>

      </div>
      <paginator (paginateEvent)="paginate($event)" [page]="query.page"></paginator>
    </div>

    <!-- Sorry Dog -->
    <div *ngIf="jobsList?.length<1">
      <div class="row d-flex justify-content-center">
        <div class="col">
          <mat-card>
            <mat-card-title>
              <p> Lo Sentimos. No hemos encontrado ningún Compi que trabaje de "{{query.jobName}}" en "{{query.addressQuery}}"</p>
            </mat-card-title>
          </mat-card>
        </div>
      </div>
      <img class="sorry-dog" src="/assets/images/charles-deluvio-K4mSJ7kc0As-unsplash-cut-md.png" alt="">
    </div>
  </div>

    <div class="row">
      <div *ngIf="!jobsList" class="col d-flex justify-content-center mt-5">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</main>
