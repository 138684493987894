import { JobModel } from '../../data/jobModel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class ApiService {
 public static readonly BASE_PATH = 'api/Server';
constructor(private http: HttpClient) {
  
}
private combineUrl(target: string): string {
 return ApiService.BASE_PATH + '/' + target;
 }
public accountLogin(userInfo: any) {
 this.http.post(this.combineUrl('Account/Login'), userInfo);
 }
public getJobs(): Observable<JobModel[]> {
 return this.http.get<JobModel[]>(this.combineUrl('Cities/All'));
 }
public citiesAdd(cityName: string): Observable<JobModel> {
 return this.http.post<JobModel>(this.combineUrl('Cities/Add'), cityName);
 }
public citiesGet(id: number): Observable<JobModel> {
 return this.http.post<JobModel>(this.combineUrl('Cities/Get'), id);
 }
}
