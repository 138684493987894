
<!-- [ngClass]='{mini: mini}'  -->
<div class="prices" >

  <!-- Bigs -->
  <div *ngIf="!mini">
    <div *ngFor="let price of prices" (click)="rate(price)" matTooltip="{{price.rateLabel}}" class="input" [class.active]="price.highlighted === true">
      <mat-icon *ngIf="price.highlighted" ngClass="{price.highlighted ? 'highlighted' : ''}" >monetization_on</mat-icon>
      <mat-icon *ngIf="!price.highlighted" ngClass="{price.highlighted ? 'highlighted' : ''}"  fontSet="material-icons-outlined">monetization_on</mat-icon>
    </div>
  </div>

  <!-- Minis -->
  <div *ngIf="mini" class="mini"  matTooltip="{{priceRate.rateLabel}}" [matTooltipShowDelay]="300">
    <div *ngFor="let price of prices" class="input" [class.active]="price.highlighted === true">
      <mat-icon *ngIf="price.highlighted" ngClass="{price.highlighted ? 'highlighted' : ''}" >monetization_on</mat-icon>
      <mat-icon *ngIf="!price.highlighted" ngClass="{price.highlighted ? 'highlighted' : ''}"  fontSet="material-icons-outlined">monetization_on</mat-icon>
    </div>
  </div>

</div>
