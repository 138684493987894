import { Injectable } from '@angular/core';
import * as Realm from "realm-web";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _app:Realm.App<any, any>
  private APP_ID = "application-0-uqxpi"

  constructor() {
    this._app =new Realm.App({ id: this.APP_ID});
  }

  public get app(){
    return this._app
  }

  public get appId(){
    return this.APP_ID
  }

  async getAccessToken():Promise<string>{
      if (!this.app.currentUser) {
        // If no user is logged in, log in an anonymous user
        await this.app.logIn(Realm.Credentials.anonymous());
      } else {
        // The logged in user's access token might be stale,
        // Refreshing custom data also refreshes the access token
        await this.app.currentUser.refreshCustomData();
      }
      // Get a valid access token for the current user
      return this.app.currentUser.accessToken;
  }
}
