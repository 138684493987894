import { CoordinatePoint, GeolocationService } from './geolocation.service';
import { jobs, JobModel } from '../data/jobModel';
import { Injectable } from '@angular/core';
import { Address, CompiModel } from "../data/compi-model";
import * as Realm from "realm-web";
import {ObjectID} from 'bson'
import { NominatimModel } from '../data/nominatim-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import {Observable, of } from 'rxjs';
import { environment } from '../app.module';


export interface ApiQuery{
  jobName:string;
  lat:number;
  lon:number;
  maxPrice?:number;
  page?:number;
}

export interface JobQuery{
  page?: number;
  jobName:string;
  position?:CoordinatePoint;
  addressQuery:string;
  stars?:number;
  price?:number;
  punctuality?:number
}

@Injectable({
  providedIn: 'root'
})
export class CompiLoaderService {
  private _activeCompi:CompiModel
  private _activeJob:JobModel;
  private app:Realm.App<any, any>
  private db:globalThis.Realm.Services.MongoDBDatabase
  private jobUrl
  private compiUrl

  private APP_ID = "application-0-uqxpi"
  httpOptions = {
    headers: new HttpHeaders(
      { 'Content-Type': 'application/ejson'}),
  };

  constructor(private geolocation: GeolocationService, private http: HttpClient)
    {
      this.app =new Realm.App({ id: this.APP_ID});
      if ( environment.useMockServer) {
        this.jobUrl = new URL("http://job-service")
        this.compiUrl = new URL("http://compi-service")
      }else{
        this.jobUrl = new URL(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/application-0-uqxpi/service/jobs/incoming_webhook/getJobsBasic`)
        this.db = this.app.currentUser.mongoClient("mongodb-atlas").db("Compis")
      }
  }

  // Getters
  get activeJob():JobModel {
    return this._activeJob
  }

  get activeCompi():CompiModel {
    return this._activeCompi
  }

  // Setters
  set activeJob(job:JobModel){
    this._activeJob = job
  }

  set activeCompi(compi:CompiModel){
    this._activeCompi = compi
  }

  getJobById(id:string|ObjectID){
    if (environment.useMockServer) {
      return this.http.get<JobModel>(`${this.jobUrl.toString()}/${id}`,this.httpOptions).pipe(
        catchError(this.handleError<JobModel>('getJobById', undefined))
        ).toPromise();

    }
    return this.db.collection("jobs").findOne({_id: new ObjectID(id)})
  }

  getCompiById(id:string|ObjectID){
    if (environment.useMockServer) {
      return this.http.get<CompiModel>(`${this.compiUrl.toString()}/${id}`,this.httpOptions).pipe(
        catchError(this.handleError<CompiModel>('getCompiById', undefined))
        ).toPromise();
    }
    return this.db.collection("compis").findOne({_id: new ObjectID(id)})
  }

  async getJobs(query, page:number=0):Promise<JobModel[]>{
    let position:NominatimModel[]|CoordinatePoint[] = await this.geolocation.getPositionFromAddress(query.addressQuery)
    if(!position || position.length <= 0 ){
      position = await this.geolocation.getPositionFromBrowser()
    }
    let apiQuery:ApiQuery = {
      jobName: query.jobName,
      lat: +position[0].lat,
      lon: +position[0].lon,
      page: query.page
    }
    let queryUrl = this.jobUrl
    // @ts-ignore
    queryUrl.search = new URLSearchParams(apiQuery).toString()

    query.price? (apiQuery.maxPrice=+query.price) : false ;
      return this.http.get<JobModel[]>(queryUrl.toString(),this.httpOptions)
        .pipe(
          tap(values => {
            values.map(job => {
              //TODO: [CF-50] Make some reliable EJSON parsing for this values
              // @ts-ignore
              job._id?.$oid ? job._id = job._id.$oid : job._id
              // @ts-ignore
              job.stars?.$numberInt?job.stars= +job.stars.$numberInt: +job.stars
              // @ts-ignore
              job.date?.$date ? job.date = new Date(+job.date.$date.$numberLong): job.date = new Date(+job.date)
              // @ts-ignore
              job.price.value?.$numberDouble ? job.price.value = +job.price.value.$numberDouble: 0
              // @ts-ignore
              job.price.rate?.$numberInt ? job.price.rate = +job.price.rate.$numberInt : job.price.rate
              // @ts-ignore
              job.compi.punctuality?.$numberInt ? job.compi.punctuality = +job.compi.punctuality.$numberInt : job.compi.punctuality
            });
          }),
          catchError(this.handleError<JobModel[]>('getJobs', []))
        ).toPromise();
  }

  parseKeys(object):any{
    if(Array.isArray(object)){
      object.forEach(item => {
        this.parseKeys(item)
       }
      )
    }
    else if (typeof object === 'string' || object instanceof String){
      return
    }
    else{
      Object.keys(object).forEach(key =>{
        this.parseKeys(object[key])
         this.parseValue(object)
      })
    }

  }

  parseValue(value){
    if(value.$date){
      return new Date(+value.$date)
    }
    else if(value.$numberDouble){
      return +value.$numberDouble
    }
    else return value
  }


    /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // populateDB(){
  //     let DresdenAddress:Address = {
  //       city: "Dresden",
  //       postcode: "01217",
  //       coordinatePoint: new CoordinatePoint(51.029064,13.748912)
  //     }
  //     let AguascalientesAddress:Address = {
  //       city: "Aguascalientes",
  //       postcode: "20130",
  //       coordinatePoint:  new CoordinatePoint(21.913331,-102.316837)
  //     }
  //     let compis:CompiModel[] = [];
  //     compis.push(this.genCompi('Benito Camelo','BenitoCamelo', '1234', DresdenAddress))
  //     compis.push(this.genCompi('Mr Fontanas','MrFontanates',"1235", AguascalientesAddress))
  //     compis.push(this.genCompi('Nacho'))
  //     compis.push(this.genCompi('Doña Lupe'))
  //     compis.push(this.genCompi('Genaro Machardo'))
  //     compis.push(this.genCompi('Chapulin Colorado'))
  //     compis.push(this.genCompi('Don IQ'))
  //     compis.push(this.genCompi('DJoto'))
  //     compis.push(this.genCompi('Mr Chingon'))
  //     compis.push(this.genCompi('BendoBarato'))
  //     for (let index = 0; index < 10 ; index++) {
  //       compis.push(this.genCompi())
  //     }
  //     compis.push()

  //     let jobs:JobModel[] = [];
  //     compis.forEach(compi => {

  //       let job = this.genJob(compi)
  //       compi.jobs.forEach(jobId => {
  //         // jobs.push(this.genJob(compi.id, compi.address.coordinatePoint,"puta", jobId))
  //         compi.jobs.push(jobId)
  //       });
  //       jobs.push(job);
  //       // compi.jobs.push(job.id)
  //       // this.db.collection("compis").doc(compi.username).set(compi)
  //       // this.db.collection("jobs").doc(job.id).set(job)

  //     });

  //   }

  // genCompi(thisname?, userN?:string, jobId?:string, address?:Address):CompiModel{
  //   let compi:CompiModel = {
  //     id: faker.random.number(),
  //     name: thisname? thisname: `${faker.name.firstName()} ${faker.name.lastName()}`,
  //     address: address?address:{
  //       postcode: faker.address.zipCode().toString(),
  //       city: "Somcity",
  //       coordinatePoint:   new CoordinatePoint(Math.random()*1+51.029064,Math.random()*1+13.748912)
  //     },
  //     phone: faker.phone.phoneNumber(),
  //     profilePic: faker.image.avatar(),
  //     username: userN ? userN: faker.internet.userName(),
  //     stars: Math.round(Math.random()*5),
  //     aboutUs: faker.lorem.paragraphs(),
  //     punctualityScore: Math.round(Math.random()*2 + 1),
  //     jobs:[
  //     ],
  //     datesUnavailable:[
  //       DateTime.local().plus({days:3}).toJSDate()
  //     ]
  //   }
  //   return compi
  // }

  // genJob(compi:CompiModel, jobName?:string, jobId?:string){
  //   let job:JobModel = {
  //     id: jobId ? jobId : faker.random.uuid(),
  //     fake: true,
  //     name: "Fontanero",
  //     compi:compi.id,
  //     date: Date.now(),
  //     location: compi.address.coordinatePoint,
  //     jobPic: faker.image.image(),
  //     keywords: "Yo Trabajo de Fontanero".toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(" "),
  //     price: {
  //       value:Math.round(Math.random()*500 ),
  //       attributes:["perHour","show"]
  //     },
  //     description: faker.lorem.paragraph(),
  //   }
  //   return job;
  // }

}
