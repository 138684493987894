import { MongodbService } from './../../services/mongodb.service';
import { ApolloService } from './../../services/apollo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JobModel, JobModelAttributes } from '../../data/jobModel';
import { JobQuery } from './../../services/compi-loader-service.service';
import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav/sidenav';
import { UtilitiesService } from "../../services/utilities.service";
import { CompiLoaderService } from "../../services/compi-loader-service.service";
import { ApolloServiceProvider } from 'src/app/services/apollo.service.provider';
import {HttpLink} from 'apollo-angular/http';
import { AuthenticationService } from '../../services/authentication.service';
import * as Realm from "realm-web";

@Component({
  selector: 'app-compi-list',
  templateUrl: './compi-list.component.html',
  styleUrls: ['./compi-list.component.scss'],
})
export class CompiListComponent implements OnInit {

  jobsList: JobModel[];
  query: JobQuery = {jobName:"", addressQuery:""};
  private app:Realm.App<any, any>
  private APP_ID = "application-0-uqxpi"

  constructor(private router:Router, public utilities: UtilitiesService, public mongodb:MongodbService, private route:ActivatedRoute, private compiLoader:CompiLoaderService) {
    this.app =new Realm.App({ id: this.APP_ID});
    this.app.currentUser.mongoClient
  }

  ngOnInit(): void {
    // this.compiService.populateDB()
    this.route.params.subscribe(params => {

      this.query.jobName = params.jobName;
      this.query.addressQuery = params.addressQuery;
      this.query.price = params.price;
      this.query.punctuality = params.punctuality
      this.query.stars = params.stars;
      this.query.page = params.page ? params.page : 1;

      this.utilities.query.next(this.query)
      this.getJobs();
    })
  }

  async getJobs(): Promise<void> {
    this.route.params.subscribe(async params => {
      let results = await this.compiLoader.getJobs(params)
      this.jobsList = results
    })
  }

  // TODO: [CF-61] try this without going through the whole navigation process:
  paginate(page){
    this.query.page = page
    this.jobsList = undefined
    this.router.navigate(['compis', this.query]);
  }
}
