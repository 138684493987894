<app-navbar></app-navbar>
<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-sidenav-container  class="example-sidenav-container"
                         [style.marginTop.px]="56">


    <!-- Filter -->
    <mat-sidenav class="shadow" #sidenav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <div class="container pt-3">
        <div class="row">
          <div class="col d-flex justify-content-end">
            <button mat-icon-button (click)="closeRightSidenav()" class="mb-3" aria-label="Example icon button with a plus one icon">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <autocomplete-search (newSearchEvent)="onSearch($event)" [(jobValue)]="query.jobName" [(addressValue)]="query.addressQuery"></autocomplete-search>
        <app-filter [(stars)]="query.stars" [(punctuality)]="query.punctuality" [(price)]="query.price"></app-filter>
      </div>
    </mat-sidenav>

    <!-- Content -->
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

