import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() page = 0
  @Output() paginateEvent = new EventEmitter();

  constructor() {
  }

  paginate(pageNumber){
    this.paginateEvent.emit(pageNumber);
  }

}
