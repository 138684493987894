<div *ngIf="compi" class="page">
  <section class="container">
    <div class="row mt-5">
      <!-- Image -->
      <div class="col-12 col-md-4 mb-4 mb-md-0 d-flex justify-content-center">
        <img class="profile-pic" src="{{compi.profilePic}}" alt="Profile Pic">
      </div>
      <!-- Infos -->
      <div class="col-12 col-md-8">
        <div class="row mb-4 my-md-5">
          <div class="col-12 col-md">
            <h1 class="mat-h1 mb-0 text-center text-md-left">{{compi.firstName}} {{compi.lastName}}</h1>
            <p class="mat-caption address text-center text-md-left">{{compi.address.city}}</p>
            <div class="text-center text-md-left">
              <app-star-rating [mini]="true" [startingValue]="job.stars"></app-star-rating>
            </div>
            <div class="text-center text-md-left">
              <app-punctuality-rating [mini]="true" [punctualityScore]="job.compi.punctuality" ></app-punctuality-rating>
            </div>
          </div>
          <div class="col-md d-none d-md-block">
            <p *ngIf="job.price.show" class="price mb-0 mat-display-1 text-center text-md-center">{{job.price.value | currency}}</p>
            <br>
            <div class="px-5">
              <button class="my-1 btn-block body-2 btn-lg text-center" mat-raised-button color="primary"><span class="">Contactar</span></button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-justify">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container">
    <h1 class="text-center mat-display-3">{{job.name}}</h1>
    <div class="row">
      <div class="col col-md-8">
        <mat-accordion class="example-headers-align" multi>

          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Descripción
              </mat-panel-title>
            </mat-expansion-panel-header>
            {{job.description }}
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Sobre mi
              </mat-panel-title>
            </mat-expansion-panel-header>
            {{compi.aboutUs}}
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Reseñas
              </mat-panel-title>
            </mat-expansion-panel-header>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="col-0 col-md-4">
        <!-- TODO: [CF-52] implement Invalid Dates -->
        <app-date-time-picker [invalidDates]=""></app-date-time-picker>
      </div>
    </div>
  </section>
</div>
