<mat-card class="my-2 fitler">
  <mat-card-title>Filtro</mat-card-title>
  <mat-card-content>
    <div class="example-action-buttons">
      <button class="mx-1" mat-stroked-button (click)="accordion.openAll()" color="primary">Ver todos los filtros</button>
      <button class="mx-1" mat-stroked-button (click)="accordion.closeAll()">Cerrar los filtros</button>
    </div>

    <mat-accordion class="example-headers-align" multi>
<!-- Estrellas -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex justify-content-start">
            <span class=" d-flex my-auto">Estrellas</span>
          </mat-panel-title>
          <mat-panel-description class="d-flex justify-content-between pr-2 mr-0 w-50">
            <p class="my-auto text-truncate">Calidad de su servicio</p>
            <mat-icon>grade</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>Por lo menos:</p>
        <app-star-rating (newStarEvent)="newStarEvent($event)" [startingValue]="stars"></app-star-rating>
      </mat-expansion-panel>

<!-- Precios -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex justify-content-start">
            <span class=" d-flex my-auto">
            Precios</span>
          </mat-panel-title>
          <mat-panel-description class="d-flex justify-content-between pr-2 mr-0 w-50" >
            <p class="my-auto text-truncate">¿Cuál es su presupuesto?</p>
            <mat-icon>monetization_on</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <price-rating (newPriceEvent)="newPriceEvent($event)"  [startingValue]="price"></price-rating>
      </mat-expansion-panel>

<!-- Punctuality -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex justify-content-start">
            <span class=" d-flex my-auto">
            Puntualidad</span>
          </mat-panel-title>
          <mat-panel-description class="d-flex justify-content-between pr-2 mr-0 w-50" >
            <p class="my-auto text-truncate">Puntualidad de su Compi</p>
            <mat-icon>alarm_on</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>Por lo menos:</p>
        <app-punctuality-rating (newPunctualityEvent)="newPunctualityEvent($event)"  [startingValue]="punctuality"></app-punctuality-rating>
      </mat-expansion-panel>


      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex justify-content-start">
            <span class=" d-flex my-auto">
            Fechas</span>
          </mat-panel-title>
          <mat-panel-description class="d-flex justify-content-between pr-2 mr-0 w-50">
            <p class="my-auto text-truncate">Intervalo de fechas de servicio</p>
            <div>
              <mat-icon>date_range</mat-icon>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        ¿Para cuando necesita su servicio?
        <br>
        <br>
        <mat-form-field color="primary" appearance="fill">
          <mat-label>Intervalo de fechas</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker" >
            <input matStartDate formControlName="start" placeholder="desde">
            <input matEndDate formControlName="end" placeholder="hasta">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker color="accent"></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha de inicio invalida</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">La fecha final no es valida</mat-error>
        </mat-form-field>

        <mat-datepicker #picker ></mat-datepicker>
      </mat-expansion-panel>
    </mat-accordion>


  </mat-card-content>
</mat-card>
