import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { UtilitiesService } from "../../services/utilities.service";
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation : ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {

  constructor(public utilities:UtilitiesService) { }

  ngOnInit(): void {

  }

}
