import { JobModel } from './../../../data/jobModel';
import { IGetData } from './../in-memory-data.service';
import { RequestInfo } from 'angular-in-memory-web-api';

export class JobsAllMock implements IGetData {
    getData(payload: RequestInfo, db?: any): any {
      let pageSize = 10
      let pageRequest = +payload.query.get("page")
      let page = pageRequest ? pageRequest : 1
      const jobsDb:JobModel[] = db['compisDb'].jobs;
      // payload.query.page

      // Find by ID
      let job = jobsDb.find(job =>{
        return job._id==payload.id})

      if(job){
        return job
      }
      // Find by jobName
      let filteredJobs = []
      if(payload.query.get("jobName")){
        let jobName = payload.query.get("jobName").toString().replace("+"," ")
        filteredJobs = jobsDb.filter(job => {
          return  jobName.toLowerCase().includes(job.name.toLowerCase())
        })
      }

      filteredJobs = filteredJobs.slice(page*pageSize, page*pageSize + pageSize)
      return filteredJobs;
    }

}
