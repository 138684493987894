<mat-card color="accent">
  <mat-card-title>Busqueda</mat-card-title>
  <mat-card-content >
    <form [formGroup]="jobsForm" (ngSubmit)="onSubmit()">
      <div class="row px-md-5">
        <!-- BUSQUEDA -->
        <div class="col-12 col-xl-7 pr-xl-1">
          <mat-form-field class="autocomplete-main-search w-100" color="accent" appearance="outline">
            <input type="text"
            matInput
            placeholder={{jobPlaceholder}}
            formControlName="jobCategory"
            [matAutocomplete]="autoGroup">
            <button class="mr-2" mat-button matPrefix mat-icon-button aria-label="Search"><mat-icon>search</mat-icon></button>
            <button mat-button *ngIf="jobsForm.value.jobCategory" matSuffix mat-icon-button aria-label="Clear" (click)="_clearFilter('jobCategory')"><mat-icon>close</mat-icon></button>

              <!-- #docregion mat-autocomplete -->
              <mat-autocomplete #autoGroup="matAutocomplete">
                <mat-optgroup *ngFor="let group of jobCategoriesOptions | async" [label]="'----- ' + group.category + ' -----' ">
                  <mat-option *ngFor="let job of group.jobs" [value]="job">
                    {{job}}
                  </mat-option>
              </mat-optgroup>
            </mat-autocomplete>
            <!-- #enddocregion mat-autocomplete -->
          </mat-form-field>
        </div>

        <!-- Postal Code -->
        <div class="col-12 col-xl-5 pl-xl-1">
          <mat-form-field class="autocomplete-main-search w-100" color="accent" appearance="outline">
            <input type="text"
            matInput
            placeholder="{{addressPlaceholder}}"
            name="addressQuery"
            formControlName="addressQuery">
            <button mat-button *ngIf="jobsForm.value.addressQuery" matSuffix mat-icon-button aria-label="Clear" (click)="_clearFilter('addressQuery')"><mat-icon>close</mat-icon></button>
          </mat-form-field>
        </div>
      </div>

      <!-- Button -->
      <div class="row px-md-5">
        <div class="col ">
          <button class="my-1 btn-block body-2 btn-lg text-center" mat-raised-button color="primary"><span class="">Buscar</span></button>
        </div>
      </div>

    </form>
</mat-card-content>
</mat-card>

