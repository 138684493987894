import { DateTime } from 'luxon';
import { Component, ViewEncapsulation, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';

export interface DaysOfTheWeek{
  day:string;
  active:boolean;
}

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  encapsulation:ViewEncapsulation.None
})

export class DateTimePickerComponent implements OnInit {

  @Input() invalidDates:Date[]

  @ViewChild(MatAccordion) accordion: MatAccordion;

  singleDate:boolean=true;

  constructor() { }

  ngOnInit(): void {
  }

  daysSelected: any[] = [];
  daysOfTheWeek: DaysOfTheWeek[] = [
    {day: 'L', active: false},
    {day: 'M', active: false},
    {day: 'M', active: false},
    {day: 'J', active: false},
    {day: 'V', active: false},
    {day: 'S', active: false},
    {day: 'D', active: false}
  ];
  event: any;

  isSelected = (event: any) => {
    const singleDate = this.formatDate(event)
    const isDaySelected = this.daysSelected.find(x => x == singleDate)
    return isDaySelected ? "selected" : null;
  };

  select(event: any, calendar: any) {
    const date = this.formatDate(event)
    const index = this.daysSelected.findIndex(x => x == date);
    if (index < 0) this.daysSelected.push(date);
    else this.daysSelected.splice(index, 1);
    calendar.updateTodaysDate();
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date())
    const yesterday = DateTime.local().minus({days:1}).toJSDate()
    let invalidDateDays = this.invalidDates?.map(date => this.formatDate(new Date(date)))

    return ((invalidDateDays && invalidDateDays?.length > 0) ? !invalidDateDays.includes(this.formatDate(day)) : true) && day >= yesterday ;
  }

  formatDate(date:Date):string{
    let dateString = DateTime.fromJSDate(date).setLocale('es-MX').toFormat('yyyy-LL-dd');
    return dateString;
  }

  getDateFromFormat(format:string):DateTime{
    let date = DateTime.fromFormat(format, 'yyyy-LL-dd').setLocale('es-MX')
    return date
  }
}
