import { ObjectId } from 'bson';
import { BoundingSquare, CoordinatePoint } from '../services/geolocation.service';
import { ReviewModel } from './comments';

export class JobModelAttributes{
  public static PERHOUR:string="perHour"
  public static SHOW:string="show"
}

// export const JobSchema = Schema({
//   name: String,
//   fake: Boolean,
//   date: Number,
//   location: {
//     type: Schema.Types.ObjectId,
//     ref: "CoordinatePoint",
//   },
//   compi: String,
//   jobPic: String,
//   description: String,
//   reviews: [{
//     type: Schema.Types.ObjectId,
//     ref: "ReviewModel",
//   }],
//   price:{
//     value: Number,
//     attributes: [{
//       type:  Schema.Types.ObjectId,
//       ref: "JobModelAttributes"
//     }]
//   },
//   keywords: [String],
// })


export interface JobModel{
  _id:ObjectId;
  name:string;
  description:string;
  fake?:boolean;
  date?:Date;
  location: CoordinatePoint,
  compi: any,
  jobPic?: string,
  stars?:number
  reviews?:ReviewModel[];
  price:{
    show?:boolean,
    perHour?:boolean,
    value: number,
    rate?: number,
    attributes?:JobModelAttributes[]
  };
  keywords?:string[];
}

export interface JobCategory {
  category: string;
  jobs: string[];
}


 export const jobs: JobCategory[] = [
  // {
  //   category: 'Eventos',
  //   jobs: [
  //     "Fotógrafo",
  //     "DJ",
  //     "Cabina de fotos" ,
  //     "Camarógrafo" ,
  //     "Banda de eventos" ,
  //     "Cantante",
  //     "Catering para eventos ",
  //     "Músico",
  //     "Bartender" ,
  //   ]
  // },
  {
    category: 'Un Manitas?',
    jobs: [
      // "Limpieza del Hogar",
      // "Albañil",
      // "Técnico en Computación e Informática",
      // "Electricista",
      // "Carpintero",
      "Fontanero",
      "Programador Web",
      // "Yeseros",
      // "Mudanza",
      // "Jardinero",
    ]
  },
  // {
  //   category: 'Deporte',
  //   jobs: [
  //     "Entrenador personal",
  //     "Nutricionista",
  //     "Maestro de Yoga",
  //   ]
  // },
  // {
  //   category: 'Tutorias particulares y en Grupo',
  //   jobs: [
  //     "Matemáticas",
  //     "Física",
  //     "Quimica",
  //     "Inglés",
  //     "Español",
  //     "Biología",
  //   ]
  // }
 ];
