
<!-- [ngClass]='{mini: mini}'  -->
<div class="stars" >

  <div *ngIf="!mini">
    <div *ngFor="let star of stars" (click)="rate(star)" matTooltip="{{star.rateLabel}}" class="input" [class.active]="star.highlighted === true">
      <mat-icon ngClass="{star.highlighted ? 'highlighted' : ''}" >{{star.highlighted?'star':'star_outline'}}</mat-icon>
    </div>
  </div>

  <div *ngIf="mini" class="mini"  matTooltip="{{starRate.rateLabel}}" [matTooltipShowDelay]="300">
    <div *ngFor="let star of stars" class="input" [class.active]="star.highlighted === true">
      <i class="fa-star" ngClass="{star.highlighted ? 'fas' : 'far'}" ></i>
    </div>
  </div>

</div>
