import { JobModel } from '../../data/jobModel';
import { CompiModel } from './../../data/compi-model';
import { CompiLoaderService } from './../../services/compi-loader-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-job-profile',
  templateUrl: './job-profile.component.html',
  styleUrls: ['./job-profile.component.scss']
})
export class JobProfileComponent implements OnInit {

  compi;
  job

  constructor(private route: ActivatedRoute, private compiLoader: CompiLoaderService) { }

  async ngOnInit() {
    await this.compiLoader.getJobById(this.route.snapshot.params.job).then(job => {
      this.job = job
      // @ts-ignore
      this.compiLoader.getCompiById(job.compi.id ).then(compa => {
        this.compi = compa
      })
    })

  }
}
