import { JobModel, JobModelAttributes } from '../../data/jobModel';
import { CompiLoaderService } from './../../services/compi-loader-service.service';
import { CompiModel } from './../../data/compi-model';
import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationExtras} from '@angular/router';



@Component({
  selector: 'compi-card',
  templateUrl: './compi-card.component.html',
  styleUrls: ['./compi-card.component.scss']
})
export class CompiCardComponent implements OnInit {
  @Input() job:JobModel;
  showPrice:boolean;
  pricePerHour:boolean;

  constructor(private router: Router, private compiLoader:CompiLoaderService) {
  }

  ngOnInit(): void {
    // this.job.compi.profilePic ? true : this.job.compi.profilePic="../../../assets/images/user_40x40.png";
    this.job.jobPic ? true : this.job.jobPic="../../../assets/iterative_noBackground_gray.png";
    this.showPrice = this.job.price.attributes.includes(JobModelAttributes.SHOW)
    this.pricePerHour = this.job.price.attributes.includes(JobModelAttributes.PERHOUR)
  }

  navigateToJob(job){
    this.router.navigate(['trabajo', job._id]);
  }


}
