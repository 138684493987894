<mat-card *ngIf="job" class="compi-card" (click)="navigateToJob(job)" >

  <mat-card-header class="mat-card-header pb-3">
    <mat-card-subtitle class="card-title subtitle mb-0 align-self-center  ">{{job.compi.name}} - {{job.compi.city}} - {{job.date | date:' MMMM d, y, h:mm:ss:SSS a'}} </mat-card-subtitle>
    <div mat-card-avatar [ngStyle]="{'background-image':'url(' + job.compi.profilePic + ')'}" class="compi-header-image "></div>
  </mat-card-header>
  <mat-divider></mat-divider>

  <mat-card-content>
    <!-- Image for Mobile -->
    <img mat-card-image class="mt-0 d-block d-sm-none" src="{{job.jobPic}}" alt="Photo of a Shiba Inu">
    <div class="d-flex mb-3 row">

      <!-- Image for Desktop -->
      <div  class="d-flex justify-content-center mx-auto">
        <img mat-card-lg-image class="job-image d-none d-sm-block mx-3 mt-3 mb-0" src="{{job.jobPic}}" alt="Job image">
      </div>

      <div class="col">
        <mat-card-title class="m-0 card-title d-flex justify-content-between">
          <p class="mt-3 align-self-center">{{job.name}}</p>
          <!-- <p class="price text-right mt-3 align-self-end">{{job.price.value | currency}}</p> -->
        </mat-card-title>
        <p class="card-text mt-3">{{job.description}}</p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row">
      <div class="col px-3 d-flex justify-content-between">
        <div class="pt-3" style="margin-bottom:-7px;">
          <app-star-rating [mini]="true" [startingValue]="job.stars"></app-star-rating>
          <!-- <price-rating *ngIf="job.price.rate" [mini]="true" [startingValue]="job.price.rate"></price-rating> -->
          <br>
          <app-punctuality-rating *ngIf="job.compi.punctuality" [mini]="true" [punctualityScore]="job.compi.punctuality" ></app-punctuality-rating>
        </div>
        <div class="d-flex pt-3">
          <button class="align-self-center price-button" mat-raised-button color="primary">
            <p class="mb-0 text-truncate" *ngIf="showPrice">
              {{job.price.value | currency}}
              <span *ngIf="pricePerHour">/ Hora</span>
              <span *ngIf="!pricePerHour">/ Visita</span>
            </p>
            <p class="mb-0 text-truncate" *ngIf="!showPrice">Precio a acordar</p>
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
