import { InMemoryDataService } from './services/in-memory-api/in-memory-data.service';
import { MongoDbServiceProvider } from './services/mongodb.service.provider';
import { ApolloServiceProvider } from 'src/app/services/apollo.service.provider';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DemoMaterialModule } from './material-module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { PagesModule } from "./pages/pages.module";
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ComponentsModule } from "./components/components.module";
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

export const environment = {
  production: true,
  useMockServer: false
 };

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ComponentsModule,
    DemoMaterialModule,
    HttpClientModule,
    MDBBootstrapModule.forRoot(),
    PagesModule,
    environment.useMockServer ?   HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
      passThruUnknownUrl: true
    }) : []
  ],
  providers: [
    ApolloServiceProvider,
    MongoDbServiceProvider,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
  bootstrap: [
    AppComponent,
    ]
})
export class AppModule { }
