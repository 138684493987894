import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompiListComponent } from './compi-list/compi-list.component';
import { HomeComponent } from './home/home.component'

import { ComponentsModule } from "../components/components.module";
import { DemoMaterialModule } from "../material-module";
import { JobProfileComponent } from './job-profile/job-profile.component';

@NgModule({
  declarations: [
    HomeComponent,
    CompiListComponent,
    JobProfileComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    DemoMaterialModule,
  ],
  exports: [
    HomeComponent,
    CompiListComponent
  ]
})
export class PagesModule { }
