import { MongodbService } from './mongodb.service';
import { AuthenticationService } from './authentication.service';

export async function mongodbServiceFactory(authenticationService: AuthenticationService) {
  let token = await authenticationService.getAccessToken()
  console.log("New Mongo Service: " + token)
  return new MongodbService()
}

export let MongoDbServiceProvider =
{
  provide: MongodbService,
  useFactory: mongodbServiceFactory,
  deps: [AuthenticationService],
  many: true
}
