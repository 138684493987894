import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum PriceRateValues{
  NONE = "No tenemos el Precio de este Compi",
  MOSTEXPENSIVE= "Muy por encima del promedio",
  EXPENSIVE = "Por encima del promedio",
  CHEAPER = "Por debajo del promedio",
  CHEAPEST = "Muy por debajo del promedio",
}

export interface PriceRate{
  rateLabel:PriceRateValues;
  highlighted: boolean;
  rateNumber:number;
}

@Component({
  selector: 'price-rating',
  templateUrl: './price-rating.component.html',
  styleUrls: ['./price-rating.component.scss']
})
export class PriceRatingComponent implements OnInit {

  @Input() mini:boolean = false;
  @Input() startingValue:number;
  @Output() newPriceEvent:EventEmitter<number> = new EventEmitter<number>();


  priceRate:PriceRate = {rateNumber:0, highlighted: false, rateLabel:PriceRateValues.NONE}

  prices: PriceRate[] = [
    {rateNumber:1, highlighted: false, rateLabel:PriceRateValues.MOSTEXPENSIVE},
    {rateNumber:2, highlighted: false, rateLabel:PriceRateValues.EXPENSIVE},
    {rateNumber:3, highlighted: false, rateLabel:PriceRateValues.CHEAPER},
    {rateNumber:4, highlighted: false, rateLabel:PriceRateValues.CHEAPEST},
  ]

  constructor() {
  }

  ngOnInit(): void {
    if(this.startingValue){
      this.priceRate = this.getPriceRateByNumber(this.startingValue)
      this.highlight(this.priceRate)
    }
  }

  getPriceRateByNumber(value:number):PriceRate{
    value = Math.round(value);
    let star = this.prices.filter(star => star.rateNumber == value)[0]
    return star
  }

  rate(value: PriceRate){
    if(value==this.priceRate){
      this.priceRate = {rateNumber:0, highlighted: false, rateLabel:PriceRateValues.NONE}
    }else{
      this.priceRate = value
    }
    this.highlight(this.priceRate)
    this.newPriceEvent.emit(this.priceRate.rateNumber)
  }

  // This only highlights the value
  highlight(value: PriceRate) {
    this.prices.map(m => value.rateNumber >= m.rateNumber? m.highlighted=true:m.highlighted=false)
  }

}
