import { JobProfileComponent } from './pages/job-profile/job-profile.component';
import { HomeComponent } from './pages/home/home.component';
import { CompiListComponent } from './pages/compi-list/compi-list.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'compis',
    component: CompiListComponent,
    data: { title: 'Lista de Compis' }
  },
  {
    path: 'trabajo/:job',
    component: JobProfileComponent,
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
