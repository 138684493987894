
<div class="container section">
  <div class="row my-5">
    <div class="col">
      <div class="row">
        <div class="col-10">
          <h1> <span class="display-4">Compi</span><span>, el comparador de servicios más grande de México</span></h1>
        </div>
      </div>
      <div class="row d-none d-sm-block">
        <h3 class="col-12">
          <ul>
            <li><span></span>Encuentre totalmente Gratis a sus próximos prestadores de servicios.</li>
            <li><span></span>Servicios privados y de empresas.</li>
            <li><span></span>Trabajo local y honesto.</li>
            <li><span></span>Escoja con seguridad a su nuevo (Plomero, Jardinero o DJ)</li>
          </ul>
        </h3>
      </div>
    </div>
    <div class="col d-none d-lg-block">
    </div>
  </div>
      <div class="row">
        <div class="col-12">
          <autocomplete-search (newSearchEvent)="onSearch($event)" [jobPlaceholder]='jobPlaceholder'>loading...</autocomplete-search>
        </div>
      </div>
</div>
