import { AuthenticationService } from './authentication.service';
import { ApolloClient, ApolloClientOptions, ApolloLink, gql, InMemoryCache } from '@apollo/client/core';
import { HttpLink} from 'apollo-angular/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApolloService {
  private _apollo: ApolloClient<any>

  constructor(httpLink: HttpLink, token: string) {
    this.wakeUpApollo(httpLink, token).then(apollo => {
      this._apollo = apollo
    })
  }

  get apollo(): ApolloClient<any>{
        return this._apollo
  }

  async wakeUpApollo(httpLink, token):Promise<ApolloClient<any>>{

    const uri = 'https://realm.mongodb.com/api/client/v2.0/app/application-0-uqxpi/graphql';
    const http = httpLink.create({uri: uri});

    const middleware = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: new HttpHeaders().set(
          'Authorization',
          `Bearer ${token || null}`,
        ),
      });
      return forward(operation);
    });

    const link = middleware.concat(http);

    let options:ApolloClientOptions<any> = {
      link: link,
      cache: new InMemoryCache()
    }
    let apolloClient = new ApolloClient(options)
    return apolloClient
  }

  async queryJobs(jobName:string){
    return await this._apollo.query({
      query: gql`
      {
        jobs (
          query: {name: "${jobName}", price: {value:  { $gt: 20 }} }
        ){
          name
        }
      }
      `,
    })
  }
}
