<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top" [containerInside]="false">
  <!-- Navbar brand -->
  <mdb-navbar-brand>
    <div class="navbar-brand d-flex">
      <button class="mr-5" mat-icon-button (click)="utilities.toggleSidenav()"><mat-icon>search</mat-icon></button>
      <a class="d-flex px-3" matRipple href="">
        <img src="../assets/iterative.png" alt="compi_logo" height="30" class="d-inline-block my-auto" >
        <span class="my-auto ml-2" style="font-weight: bolder;">Compi</span>
      </a>
    </div>
  </mdb-navbar-brand>

  <!-- Collapsible content -->
  <links>

    <ul class="navbar-nav ml-auto mr-4">
      <li class="nav-item mr-3">
        <a class="nav-link" mat-flat-button color="primary">Iniciar Session</a>
      </li>
      <li class="nav-item">
        <a class="nav-link accent" mat-raised-button color="accent">Crear Cuenta</a>
      </li>
    </ul>
    <!-- Links -->
  </links>
  <!-- Collapsible content -->

</mdb-navbar>
<!--/.Navbar-->
