import { NominatimModel } from './../data/nominatim-model';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as geopoint from "geopoint";
import * as firebase from 'firebase/app'
import { HttpClient } from '@angular/common/http';
import { catchError, find, map, tap, timeout } from 'rxjs/operators';
import { environment } from '../app.module';

// import * as geocoder from "leaflet-control-geocoder"
// import * as geocoder from "node-open-geocoder"

export class CoordinatePoint{
  type?: string
  lat: number
  lon: number
  accuracy?: number    /**
  * Creates a new immutable GeoPoint object with the provided latitude and
  * longitude values.
  * @param lat The latitude as number between -90 and 90.
  * @param lon The longitude as number between -180 and 180.
  */
 constructor(lat: number, lon: number){
    this.lat = lat
    this.lon = lon
 }

}
export interface BoundingSquare{
  SW: firebase.default.firestore.GeoPoint,
  NE: firebase.default.firestore.GeoPoint
}

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  format = "json&addressdetails=1&limit=1"
  url = new URL("https://nominatim.openstreetmap.org/")
  postition:Map<string,NominatimModel>

  constructor(private http: HttpClient) {
    if (environment.useMockServer) {
      this.url = new URL("http://nominatim-service/")
    }
   }


  getPositionFromAddress(query:string):Promise<NominatimModel[]>{
    let apiAddressObs = this.http.get<NominatimModel[]>(`${this.url.toString()}search/${query}?format=${this.format}`).pipe(
      catchError(this.handleError<NominatimModel[]>(`getPositionFromAddress name=`))
    )
    return apiAddressObs.toPromise()
  }

  getPositionFromBrowser():Promise<CoordinatePoint[]>{
    let pointObs = new Observable<CoordinatePoint[]>(subscriber => {
      navigator.geolocation.getCurrentPosition((possition) => {
        let lat = possition.coords.latitude
        let lon = possition.coords.longitude
        let acc = possition.coords.accuracy

        let point:CoordinatePoint[] =
          [{lat:lat, lon:lon, accuracy:acc}]

        subscriber.next(point)
        subscriber.complete()
      },
      error=>{
        console.error(`Error, ${error.message}`)
        subscriber.error(error)
      },{
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      })
    })
    return pointObs.toPromise()
  }

  getBoundingSquare(point:CoordinatePoint, distance:number):BoundingSquare{
    let clientPosition = new geopoint(point.lat, point.lon)
    let typelessBoundingCoordinates = clientPosition.boundingCoordinates(distance, true)
    let SWPoint = typelessBoundingCoordinates[0]
    let NEPoint = typelessBoundingCoordinates[1]
    let boundingSquare:BoundingSquare = {
      NE: new firebase.default.firestore.GeoPoint(NEPoint.latitude(), NEPoint.longitude()),
      SW: new firebase.default.firestore.GeoPoint(SWPoint.latitude(), SWPoint.longitude()),
    }
    return boundingSquare
  }

  getDistance(point1:CoordinatePoint, point2:CoordinatePoint){
    const R = 6371e3; // metres
    let lat1 = point1.lat
    let lat2 = point2.lat
    let lon1 = point1.lon
    let lon2 = point2.lon

    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;

    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;
    const a = Math.sin(Δφ/2)  * Math.sin(Δφ/2) +
              Math.cos(φ1)    * Math.cos(φ2) *
              Math.sin(Δλ/2)  * Math.sin(Δλ/2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const d = R * c; // in metres
    return d
    console.log(`Distance: ${d}m`)
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
private handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

}
