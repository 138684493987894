import { JobQuery } from './../../services/compi-loader-service.service';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { JobCategory, jobs} from "../../data/jobModel";
import { MapType } from '@angular/compiler';

export enum SearchFields{
  JOBFIELD="jobCategory",
  ADDRESSFIELD="addressQuery"
}

/**
 * @title Option groups autocomplete
 */
@Component({
  selector: 'autocomplete-search',
  templateUrl: './autocomplete-search.component.html',
  styleUrls: ['./autocomplete-search.component.scss'],
  encapsulation : ViewEncapsulation.None,
})


export class AutocompleteSearch implements OnInit  {

  _jobValue=""

  @Input() get jobValue():string{
    return this._jobValue
  };
  @Output() jobValueChange = new EventEmitter();

  set jobValue(val){
    this._jobValue=val
    this.jobValueChange.emit(val);
  }

  @Input() jobPlaceholder:string="Búsqueda";
  @Input() addressPlaceholder:string="Ciudad/CP";
  @Input() addressValue:string

  @Output() newSearchEvent = new EventEmitter<Map<SearchFields,string>>();

  _filter = (opt: string[], value: string): string[] => {
    const filterValue = value.toLowerCase();
    return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
  };

  jobCategories = jobs
  value:string = undefined
  jobsForm: FormGroup = this._formBuilder.group({
    jobCategory: '',
    addressQuery:'',
  });

  jobCategoriesOptions: Observable<JobCategory[]>;

  constructor(private _formBuilder: FormBuilder, public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.jobsForm.get('jobCategory').setValue(this.jobValue)
    this.jobsForm.get('addressQuery').setValue(this.addressValue)
    this.route.queryParams.subscribe(params => {
    })
    this.jobCategoriesOptions = this.jobsForm.get('jobCategory')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value))
      );
  }
  private _clearFilter(filter:string):void{
    this.jobsForm.get(filter).reset()
  }

  private _filterGroup(value: string): JobCategory[] {
    if (value) {
      return this.jobCategories
        .map(group => ({category: group.category, jobs: this._filter(group.jobs, value)}))
        .filter(group => group.jobs.length > 0);
    }
    return this.jobCategories;
  }

  onSubmit(){
    let fieldsMap = new Map<SearchFields,string>([
      [SearchFields.JOBFIELD,  this.jobsForm.get('jobCategory').value],
      [SearchFields.ADDRESSFIELD,  this.jobsForm.get('addressQuery').value]
    ])
    this.newSearchEvent.emit(fieldsMap)
  }
}
