import { DateTime } from 'luxon';
import { Address, CompiModel } from '../../../data/compi-model';
import { JobModel } from '../../../data/jobModel';
import { CoordinatePoint } from '../../geolocation.service';
import { IGetData } from './../in-memory-data.service';
import * as faker from 'faker/locale/es_MX'

export class CompisInitDb implements IGetData {
    getData(payload: any, db?: any): any {

        let DresdenAddress:Address = {
          city: "Dresden",
          postcode: "01217",
          coordinatePoint: new CoordinatePoint(51.029064,13.748912)
        }
        let AguascalientesAddress:Address = {
          city: "Aguascalientes",
          postcode: "20130",
          coordinatePoint:  new CoordinatePoint(21.913331,-102.316837)
        }
        let compis:CompiModel[] = [];
        compis.push(this.genCompi('Benito Camelo','BenitoCamelo', "1234", DresdenAddress))
        compis.push(this.genCompi('Mr Fontanas','MrFontanates', "1235", AguascalientesAddress))
        compis.push(this.genCompi('Nacho'))
        compis.push(this.genCompi('Doña Lupe'))
        compis.push(this.genCompi('Genaro Machardo'))
        compis.push(this.genCompi('Chapulin Colorado'))
        compis.push(this.genCompi('Don IQ'))
        compis.push(this.genCompi('DJoto'))
        compis.push(this.genCompi('Mr Chingon'))
        compis.push(this.genCompi('BendoBarato'))
        for (let index = 0; index < 10 ; index++) {
          compis.push(this.genCompi())
        }
        compis.push()

        let jobs:JobModel[] = [];

        compis.forEach(compi => {
          compi.jobs.forEach(jobID => {
            let job = this.genJob(compi, jobID)
            jobs.push(job);
          });
          let job = this.genJob(compi)
          compi.jobs.forEach(jobId => {
            // jobs.push(this.genJob(compi.id, compi.address.coordinatePoint,"puta", jobId))
            compi.jobs.push(jobId)
          });
          jobs.push(job);
          compi.jobs.push(job._id)
        });
        return {compis,jobs}

      }

        genCompi(thisname?, userN?:string, jobId?:string, address?:Address):CompiModel{
          let compi:CompiModel = {
            id: faker.random.number(),
            name: thisname? thisname: `${faker.name.firstName()} ${faker.name.lastName()}`,
            address: address?address:{
              postcode: faker.address.zipCode().toString(),
              city: "Somcity",
              coordinatePoint:   new CoordinatePoint(Math.random()*1+51.029064,Math.random()*1+13.748912)
            },
            phone: faker.phone.phoneNumber(),
            profilePic: faker.image.people(),
            username: userN ? userN: faker.internet.userName(),
            stars: Math.round(Math.random()*5),
            aboutUs: faker.lorem.paragraphs(),
            punctualityScore: Math.round(Math.random()*2 + 1),
            jobs:[jobId],
            datesUnavailable:[
              DateTime.local().plus({days:3}).toJSDate()
            ]
          }
          return compi
        }

        genJob(compi:CompiModel, jobId?:string){
          let job:JobModel = {
            _id: jobId ? jobId : faker.random.uuid(),
            fake: true,
            name: "Fontanero",
            compi:{
              profilePic: compi.profilePic,
              id: compi.id,
              name: compi.name,
              punctuality: compi.punctualityScore,
              city: compi.address.city
            },
            date: new Date(),
            location: compi.address.coordinatePoint,
            jobPic: faker.image.image(),
            keywords: "Yo Trabajo de Fontanero".toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(" "),
            price: {
              value:Math.round(Math.random()*500 ),
              show: true,
              perHour: true,
              attributes:["perHour","show"]
            },
            description: faker.lorem.paragraph(),
          }
          return job;
        }

      }
