import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import * as Realm from "realm-web";

@Injectable({
  providedIn: 'root'
})
export class MongodbService {
  private APP_ID = "application-0-uqxpi"
  private app
  private db
  constructor() {
    this.app =new Realm.App({ id: this.APP_ID});
    this.db = this.app.currentUser.mongoClient("mongodb-atlas").db("Compis")
  }

  async findOne(collection="jobs"){
    let result = await this.db.collection(collection).findOne()
  }

  async findMany( jobName:string,collection="jobs",){
    const agg = [
      {
        '$search': {
          'text': {
            'path': [
              'name', 'description'
            ],
            'query': 'el',
            'fuzzy': {}
          }
        }
      }
    ];
    let result = await this.app.currentUser.functions.queryJobs("programador");
  }

}
