import { RequestInfo } from 'angular-in-memory-web-api';
import { CompiModel } from 'src/app/data/compi-model';
import { IGetData } from './../in-memory-data.service';

export class CompisAllMock implements IGetData {
    getData(payload: RequestInfo, db?: any): any {
        const compisDb:CompiModel[] = db['compisDb'].compis;

        // Find by ID
        let compi = compisDb.find(compi =>{
          return compi.id==payload.id})

        if(compi){
          return compi
        }
        return compisDb;
    }
}
