import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteSearch } from "./autocomplete-search/autocomplete-search.component";
import { DemoMaterialModule } from '../material-module';
import { NavbarComponent } from "./navbar/navbar.component";
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SidenavFilterComponent } from './sidenav-filter/sidenav-filter.component';
import { FilterComponent } from './filter/filter.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { CompiCardComponent } from './compi-card/compi-card.component';
import { PunctualityRatingComponent } from './punctuality-rating/punctuality-rating.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { PriceRatingComponent } from './price-rating/price-rating.component';
import { PaginatorComponent } from './paginator/paginator.component';

@NgModule({
  declarations: [
    AutocompleteSearch,
    NavbarComponent,
    SidenavFilterComponent,
    FilterComponent,
    StarRatingComponent,
    CompiCardComponent,
    PunctualityRatingComponent,
    DateTimePickerComponent,
    PriceRatingComponent,
    PaginatorComponent
  ],
  imports: [
    CommonModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MDBBootstrapModule.forRoot(),
  ],
  exports:[
    AutocompleteSearch,
    CompiCardComponent,
    NavbarComponent,
    SidenavFilterComponent,
    FilterComponent,
    PunctualityRatingComponent,
    StarRatingComponent,
    DateTimePickerComponent,
    PaginatorComponent
  ]

})
export class ComponentsModule { }
