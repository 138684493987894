<div class="clocks" >

  <div *ngIf="!mini">
    <div *ngFor="let clock of clocks" (click)="rate(clock)" matTooltip="{{clock.rateLabel}}" class="input" [class.active]="clock.highlighted === true">
      <mat-icon ngClass="{clock.highlighted ? 'highlighted' : ''}">alarm_on</mat-icon>
    </div>
  </div>

  <div *ngIf="mini && punctuality.punctualityIndex != 0" class="mini" matTooltip="{{punctuality.rateLabel}}" [matTooltipShowDelay]="300">
    <div *ngFor="let clock of clocks" class="input" [class.active]="clock.highlighted === true">
      <div class="input" >
        <mat-icon class="punctuality" [class.punctual]="clock.highlighted === true" >alarm_on</mat-icon>
      </div>
    </div>
  </div>
</div>
