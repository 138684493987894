import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
 // Firebase App (the core Firebase SDK) is always required and must be listed first
 import firebase from "firebase/app";
 // If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
 // import * as firebase from "firebase/app"

 // If you enabled Analytics in your project, add the Firebase SDK for Analytics
 import "firebase/analytics";

 // Add the Firebase products that you want to use
 import "firebase/auth";
 import "firebase/firestore";
import { MediaMatcher } from '@angular/cdk/layout';
import { UtilitiesService } from './services/utilities.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SearchFields } from './components/autocomplete-search/autocomplete-search.component';
import { JobQuery } from './services/compi-loader-service.service';
import { ApolloService } from './services/apollo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  title = 'Compi';
  firebaseConfig = {
    apiKey: "AIzaSyCjbBVSEUdsE6D6CQ-MuX5pDh4z__ZgOiE",
    authDomain: "compi-mexico.firebaseapp.com",
    databaseURL: "https://compi-mexico.firebaseio.com",
    projectId: "compi-mexico",
    storageBucket: "compi-mexico.appspot.com",
    messagingSenderId: "820037945695",
    appId: "1:820037945695:web:7866e35b7a78883ecb4eea",
    measurementId: "G-W266WQ0LH1"
  };



  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  query:JobQuery={
    jobName:"",
    addressQuery:"",
  }

  sidenavOpen: boolean;
  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public utilities: UtilitiesService, private router:Router, private apollo:ApolloService) {
    firebase.initializeApp(this.firebaseConfig);
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.utilities.query.subscribe(jobQuery =>{
      this.query = jobQuery
    })
  }

  ngAfterViewInit(): void {
    this.utilities.setSidenav(this.sidenav);
    this.sidenav.closedStart.subscribe(obs => {
      this.sidenavOpen = false;
    })

    this.sidenav.openedStart.subscribe(obs => {
      this.sidenavOpen = true;
    })
  }

  toggleRightSidenav() {
    this.utilities.toggleSidenav();
 }

 closeRightSidenav() {
   this.utilities.close();
}

 onSearch(searchMap: Map<SearchFields,string>){
  this.query.jobName=searchMap.get(SearchFields.JOBFIELD),
  this.query.addressQuery=searchMap.get(SearchFields.ADDRESSFIELD)
  this.utilities.close()
  this.router.navigate(['compis', this.query]);
 }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
