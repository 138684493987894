import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, of, Subject } from 'rxjs';
import { JobQuery } from './compi-loader-service.service';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  private sidenav: MatSidenav;
  
  query:Subject<JobQuery> = new Subject<JobQuery>();
  constructor(private router:Router) { }

  public setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;
    }

  public sidenavClosing():Observable<void>{

    return this.sidenav.closedStart
  }

  public open() {
      return this.sidenav.open();
  }


  public close() {
      return this.sidenav.close();
  }

  public toggleSidenav(): void {
    this.sidenav.toggle();
  }

  public setJobQuery(query:JobQuery){
    this.query.next(query)
    this.router.navigate(['compis', query]);
  }
}
