import { JobQuery } from './../../services/compi-loader-service.service';
import { UtilitiesService } from './../../services/utilities.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;

  _stars:number;
  _punctuality:number
  _price:number

  @Output() starsChange = new EventEmitter();
  @Output() punctualityChange = new EventEmitter();
  @Output() priceChange = new EventEmitter();

  @Input() get stars():number{
    return this._stars
  }
  @Input() get punctuality():number{
    return this._punctuality
  }
  @Input() get price():number{
    return this._price
  }

  set stars(val) {
    this._stars = val;
    this.starsChange.emit(this._stars);
  }

  set punctuality(val) {
    this._punctuality = val;
    this.punctualityChange.emit(this._punctuality);
  }

  set price(val) {
    this._price = val;
    this.priceChange.emit(this._price);
  }

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor() {
  }

  ngOnInit(): void {
  }

  newStarEvent(stars:number){
      this.stars = (stars)
  }
  newPunctualityEvent(punctuality:number){
    this.punctuality = (punctuality)
  }
  newPriceEvent(price:number){
    this.price = (price)
  }

}
