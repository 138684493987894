import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter } from 'rxjs/operators';

export enum StarRateValues{
  NONE = "No hay calificaciones",
  VERYBAD = "Pésimo",
  BAD = "Malo",
  OK = "Regular",
  GOOD = "Bueno",
  EXCELLENT = "Excelente",
}

export interface StarRate{
  rateLabel:StarRateValues;
  highlighted: boolean;
  rateNumber:number;
}

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  @Input() mini:boolean = false;
  @Input() startingValue:number;

  @Output() newStarEvent:EventEmitter<number> = new EventEmitter<number>();

  starRate:StarRate = {rateNumber:0, highlighted: false, rateLabel:StarRateValues.NONE}

  stars: StarRate[] = [
    {rateNumber:1, highlighted: false, rateLabel:StarRateValues.VERYBAD},
    {rateNumber:2, highlighted: false, rateLabel:StarRateValues.BAD},
    {rateNumber:3, highlighted: false, rateLabel:StarRateValues.OK},
    {rateNumber:4, highlighted: false, rateLabel:StarRateValues.GOOD},
    {rateNumber:5, highlighted: false, rateLabel:StarRateValues.EXCELLENT}
  ]

  constructor() {
   }

  ngOnInit(): void {
    if(this.startingValue){
      this.starRate = this.getStarRateByNumber(this.startingValue)
      this.highlight(this.starRate)
    }
  }

  getStarRateByNumber(value:number):StarRate{
    value = Math.round(value);
    let star = this.stars.filter(star => star.rateNumber == value)[0]
    return star
  }co

  rate(value: StarRate){
    if(value==this.starRate){
      this.starRate = {rateNumber:0, highlighted: false, rateLabel:StarRateValues.NONE}
    }else{
      this.starRate = value
    }
    this.highlight(this.starRate)
    this.newStarEvent.emit(this.starRate.rateNumber)
  }

  // This only highlights the value
  highlight(value: StarRate) {
    this.stars.map(m => value.rateNumber >= m.rateNumber? m.highlighted=true:m.highlighted=false)
  }
}
