import { ApolloService } from './apollo.service';
import { AuthenticationService } from './authentication.service';
import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { HttpHeaders } from '@angular/common/http';

export async function apolloServiceFactory(httpLink: HttpLink, authenticationService: AuthenticationService) {
  let token = await authenticationService.getAccessToken()
  console.debug("New Apollo Service: " + token)
  return new ApolloService(httpLink, token)
}

export let ApolloServiceProvider =
{
  provide: ApolloService,
  useFactory: apolloServiceFactory,
  deps: [HttpLink, AuthenticationService],
}
